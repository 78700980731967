import React from "react";
import logo from "../../assets/images/greenleaf-alt.png"

const Footer = () => (
  <footer>
    <div className="content space-between flex-v-center">
      <div className="footer-inner-left">
        <p>Terms of Service | Privacy Policy. | Copyright © {new Date().getFullYear()} Greenzone Technologies Limited. All rights reserved.</p>
      </div>
      <div className="footer-inner-right">
        <img src={logo} alt="" srcset="" />
      </div>
    </div>
  </footer>
);

export default Footer;
