
import React, { useEffect, useContext, useState } from 'react';


import { post, put } from '../../utility/fetch';
import coatOfArm from '../../assets/images/coat-of-arm.png';
import greenz from '../../assets/images/Greenzone.png';
import icon from '../../assets/images/Group-2.png';


import Footer from '../layouts/Footer';
import notification from '../../utility/notification';
import { Link, useNavigate } from 'react-router-dom';
import InputField from '../UI/InputField';
import axios from 'axios';
import Notify from '../../utility/notify';
import { HiOutlineEye } from "react-icons/hi2";
import { HiOutlineEyeOff } from "react-icons/hi";


const Home = (props) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [show, setShow] = useState(false)
  const [view, setView] = useState("login")
  const [userEmail, setUserEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showNew, setShowNew] = useState(false);
  const [showConNew, setShowConNew] = useState(false);


  const changeView = (views) => {
    if (views === "password") {
      if (!userEmail) {
        Notify({ title: "Error", message: "Please enter a username", type: "danger" });
        return;
      }

      if (userEmail.length < 4) {
        Notify({ title: "Error", message: "Username must be more than 4 characters", type: "danger" });
        return;
      }
    }

    // !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(email) Email validation
    setView(views);
  }

  const submitPassword = async () => {
    let res = await put(`/user/change-user-password/${userEmail}`, { password: newPassword });
    console.log(res)
    if (res.statusCode === 400) {
      Notify({ title: "Error", message: res.errorData[0], type: "danger" });
      return;
    }
    else if (res.statusCode === 404) {
      Notify({ title: "Error", message: res.errorData[0], type: "danger" });
      return;
    }
    else if (res.statusCode === 409) {
      Notify({ title: "Error", message: res.errorData[0], type: "danger" });
      return;
    }
    else if (res) {
      Notify({ title: "Success", message: "Password Changed Successfully", type: "success" });
      setNewPassword("");
      setConfirmNewPassword("");
      setUserEmail("");
      setView("login");
    }

  }

  const submitEmail = async () => {
    if (!/\S+@\S+\.\S+/.test(userEmail)) {
      Notify({ title: "Error", message: "Please enter a valid email address", type: "danger" });
      return;
    }

   

    let res = await post(`/auth/forget-password/${userEmail}`)
    if (res.statusCode === 400) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res.statusCode === 409) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res.statusCode === 404) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res.statusCode === 500) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res.statusCode === 401) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else{
      Notify({ title: "Success", message: "An email reset link has been successfully sent to your email", type: "success" });
    }
    
    
  }

  const makePostRequest = async () => {

    if (email === "" || password === "") {
      Notify({
        title: 'ACCESS DENIED',
        message: 'Please fill all the fields',
        type: 'warning',
      });
      return;
    }

    setLoading(true);
    console.log("here")
    const payload = {
      email: email,
      password: password
    }
    try {
      const data = await axios.post(
        `https://edogoverp.com/clinicapi/api/Auth/login`, payload
      );
      if (data) {
        console.log(data)
        sessionStorage.setItem('token', data.data?.token);
        sessionStorage.setItem('userId', data.data?.userId);
        sessionStorage.setItem('clinicId', data.data?.clinicId);
        // localStorage.setItem('USER_INFO', JSON.stringify(data));
        navigate('/facility');
      }

    } catch (error) {
      localStorage.removeItem('USER_INFO');
      console.log(error);
      notification({
        title: 'ACCESS DENIED',
        message: 'Sorry, This user does not have access to this application. Please Contact Admin',
        type: 'warning',
      });
    }
    // setTimeout(() => window.location.assign("/"), 6000);

    setLoading(false)
  };

  // useEffect(() => {
  //   makePostRequest();
  // }, []);

  return (
    <div className="w-100 " style={{ height: "100vh" }}>
      <div className="banner p-20">
        <div className="log flex-h-center  w-100 m-t-10">
          <div className=''>
            <div className='flex-h-center'>
              <div className='m-l-20'><img src={icon} alt='' width={26} height={26} /></div>
              <div> <img src={greenz} alt='' width={100} height={26} /></div>
            </div>

            {view === "login" && <div className='w-100'>
              <div className=' m-t-40'>
                <label>Email</label>
                <div className='flex w-100 flex-v-center login-input p-10 m-t-10'>
                  <input className='w-100 ' type="text" name={"email"} value={email} placeholder={"username or email"} onChange={(e) => setEmail(e.target.value)} />
                </div>

              </div>
              <div className='m-t-40'>
                <label>Password</label>
                <div className='flex w-100 flex-v-center login-input p-10 m-t-10'>
                  <input className="w-100" type={show ? "text" : `password`} name={"password"} value={password} placeholder={"password"} onChange={(e) => setPassword(e.target.value)} />
                  {show ? <HiOutlineEye onClick={() => setShow(!show)} className='pointer' size={20} /> : <HiOutlineEyeOff onClick={() => setShow(!show)} className='pointer' size={20} />}
                </div>

              </div>

              <div className='m-t-40'> <button disabled={loading} onClick={makePostRequest} className='w-100 btn'>Login</button></div>
              <div className='m-t-10'>

                <p onClick={() => changeView("username")} className='text-green pointer underline'>
                  Forgot Password?
                </p>
              </div>
            </div>
            }
            {view === "username" &&
              <div>
                <h3 className='center-text m-t-40'>
                  Hey, It seems you can't remember your password
                </h3>
                <p className='center-text m-t-20'>
                  Please enter your email to reset your password
                </p>

                <div className='flex w-100 flex-v-center login-input p-10 m-t-10'>
                  <input className='w-100 ' type="text" name="userEmail" value={userEmail} placeholder="Email" onChange={(e) => setUserEmail(e.target.value)} />
                </div>

                <div className='m-t-40'> <button disabled={loading} onClick={() => submitEmail()} className='w-100 btn'>Submit</button></div>
                <div onClick={() => setView("login")} className='m-t-10 underline pointer'>Back to Sign in</div>
              </div>
            }

            {/* {view === "password" &&
              <div>
                <h3 className='center-text m-t-40'>
                  Hey, It seems you can't remember your password
                </h3>
                <p className='center-text m-t-20'>
                  Please enter your new password.
                </p>

                <div className='flex w-100 flex-v-center login-input p-10 m-t-10'>
                  <input className="w-100" type={show ? "text" : `password`} name={"password"} value={newPassword} placeholder={"password"} onChange={(e) => setNewPassword(e.target.value)} />
                  {show ? <HiOutlineEye onClick={() => setShowNew(!showNew)} className='pointer' size={20} /> : <HiOutlineEyeOff onClick={() => setShow(!show)} className='pointer' size={20} />}
                </div>
                <div className='flex w-100 flex-v-center login-input p-10 m-t-10'>
                  <input className="w-100" type={show ? "text" : `password`} name={"password"} value={confirmNewPassword} placeholder={"password"} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                  {show ? <HiOutlineEye onClick={() => setShowConNew(!showConNew)} className='pointer' size={20} /> : <HiOutlineEyeOff onClick={() => setShow(!show)} className='pointer' size={20} />}
                </div>
                <p className='center-text m-t-20 text-red'>
                  {(newPassword !== confirmNewPassword) && "Password mismatch, please make sure your passwords match."}
                </p>


                <div className='m-t-40'> <button disabled={loading || (newPassword !== confirmNewPassword)} onClick={submitPassword} className='w-100 btn'>Submit</button></div>
                <p onClick={() => setView("login")} className='underline m-t-20 pointer'>back to login</p>
              </div>
            } */}



          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
